import "./Flag.scss";

const Flag = ({ theme, toggle }) => {
  return (
    <div className="rzv-messagebar">
        <div className="rzv-messagebar__top"></div>

        <div className="rzv-messagebar__bottom"></div>
    </div>
  );
};

export default Flag;

// #ffe005
