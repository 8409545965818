function Gallery() {
    return (
        <div className="rzv-gallery">
            <h1>Title</h1>
            <p>Paragraph</p>

            <div className="rzv-gallery__wr">
                <ul>
                    <li>
                        <img src="" />
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default Gallery;